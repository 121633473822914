//
// Main Stylesheet
//




////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Include global config
@import "config";

// 2: Include layout config
@import "global/layout/config";

// 3: Bootstrap framework includes
@import "global/integration/frameworks/bootstrap/variables";
@import "../../../tools/node_modules/bootstrap/scss/bootstrap";
@import "global/integration/frameworks/bootstrap/bootstrap.scss";

// 4: Components include
@import "global/include";

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 5: Layout config
@import "global/layout/mobile-header/config";
@import "global/layout/aside/config";
@import "global/layout/footer/config";

// 6: Layout includes
@import "global/layout/base/base";

@import "global/layout/mobile-header/mobile-header";
@import "global/layout/aside/aside";
@import "global/layout/aside/primary";
@import "global/layout/aside/secondary";

@import "global/layout/content/content";

@import "global/layout/footer/footer";
