//
// Aside Primary
//



.kt-aside__primary {
    display: flex;
    flex-direction: column;
    height: 100%;

    // Aside Top
    .kt-aside__top {
        text-align: center;
        margin-top: 1.2rem;
    }

    // Aside Middle
    .kt-aside__middle {
        flex: 1;
        overflow: auto;

        .kt-aside__nav {
            padding: 0;
            margin-top: 2.5rem;

            .kt-aside__nav-item {
                list-style-type: none;
                padding: 0;
                text-align: center;
                margin-top: 1rem;
                @include kt-transition();

                .kt-aside__nav-link {
                    width: 3.4rem; /* updated */
                    height: 3.4rem; /* updated */
                    border-radius: 50px; /* updated */
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;

                    .kt-badge {
						width: 18px;
                        position: absolute;
                        left: 20px;
                        top: 0px;
                    }

                    i {
                        font-size: 1.3rem;
                        color: #fff;
                        font-weight: 500;
                    }

                    &.active {
                        background-color: #FFFFFF; /* updated */
                        color:#2F2963;
                        svg [stroke="#fff"] {
                            stroke: #2F2963;
                        }
                        svg [fill="#fff"] {
                            fill: #2F2963;
                        }
                    }
                }

                &:hover {
                    .kt-aside__nav-link {
                        background-color: #FFFFFF;
                        color:#2F2963;
                        @include kt-transition();

                        svg [stroke="#fff"] {
                            stroke: #2F2963;
                        }
                        svg [fill="#fff"] {
                            fill: #2F2963;
                        }
                    }
                }
            }
        }
    }

    // Aside Bottom
    .kt-aside__bottom {
       .kt-aside__nav {
            padding: 0;

            .kt-aside__nav-item {
                list-style-type: none;
                padding: 0;
                text-align: center;
                margin-top: 1rem;
                @include kt-transition();

                .kt-aside__nav-link {
                    width: 3.4rem; /* updated */
                    height: 3.4rem; /* updated */
                    border-radius: 50px; /* updated */
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    position: relative;

					.kt-badge {
						width: 18px;
                        position: absolute;
                        left: 20px;
                        top: 0px;
                    }

                    i {
                        font-size: 1.3rem;
                        color: #fff;
                        font-weight: 500;
                    }

                    .kt-aside__nav-langs {
                        border-radius: 50% !important;
                        width: 2rem;
                        height: 2rem;
                    }

                    img {
                        max-height: 3rem;
                        max-width: 3rem;
                        border-radius: 5px;
                    }

                    .kt-aside__nav-username {
                        height: 3rem;
                        width: 3rem;
                        border-radius: 5px;
                        display: block;
                        margin: 0 auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.5rem;
                        font-weight: 500;
                        color: #fff;
                    }
                }

                &:hover {
                    .kt-aside__nav-link {
                        background-color: #FFFFFF;
                        color:#2F2963;
                        @include kt-transition();

                        svg [stroke="#fff"] {
                            stroke: #2F2963;
                        }
                        svg [fill="#fff"] {
                            fill: #2F2963;
                        }
                    }
                }

                // Languages
                .dropdown-menu {
                    .kt-nav {
                        .kt-nav__item {
                            .kt-nav__link-icon {
                                img {
                                    border-radius: 50%;
                                    width: 18px;
                                }
                            }
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 2rem;
                    margin-top: 1.6rem;
                }
            }
        }
    }
}

