//
// Header Mobile
//



// Desktop mode
@include kt-desktop {
	.kt-header-mobile {
		display: none;
	}
}

// Tablet & mobile mode
@include kt-tablet-and-mobile {
	.kt-header-mobile {
		display: flex;
		justify-content: space-between;
		padding: 0 kt-get($kt-page-padding, mobile);
		height: kt-get($kt-mobile-header-config, self, default, height);
		min-height: kt-get($kt-mobile-header-config, self, default, height);
		background-color: #fff;
		box-shadow: 0px 1px 20px -3px rgba(0,0,0, 0.15);
		position: relative;
		z-index: 2;
		
		// Fixed
		.kt-header-mobile--fixed & {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: kt-get($kt-mobile-header-config, self, fixed, zindex);
		}

		// Logo
		.kt-header-mobile__logo {
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		// Toolbar
		.kt-header-mobile__toolbar {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			@include kt-burger-icon-build(kt-header-mobile__toggler, kt-get($kt-mobile-header-config, toolbar, toggler));
			@include kt-burger-icon-skin(kt-header-mobile__toggler, kt-get($kt-mobile-header-config, toolbar, toggler, color));

			.kt-header-mobile__toggler {
				margin: 0 0 0 1.5rem;
			}
		}
	}
}