//
// Widget 9
//




// General Mode
.kt-widget-9 {
	display: flex;
    flex-direction: column;
    height: 100%;
	width: 100%;
		
	.kt-widget-9__panel {
		flex-grow: 1;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;

		.kt-widget-9__legends {
			display: flex;
			align-items: center;

			.kt-widget-9__legend {
				display: flex;
				align-items: center;
				padding-right: 2rem;

				.kt-widget-9__legend-bullet {
					width: 15px;
					height: 5px;
					border-radius:3px;
				}

				.kt-widget-9__legend-label {
					padding-left: 0.75rem;
					font-weight: 500;
					color: kt-base-color(label, 2);
				}
			}
		}

		.kt-widget-9__toolhar {
			padding-left: 0.5rem;
		}
	}  

	.kt-widget-9__chart {
		margin-top: 40px;
		position: relative;
	}
}