//
// Footer
//



.kt-footer {
	padding: 20px 0;

	.kt-container {		
		display: flex;
		justify-content: space-between;
	}

	.kt-footer__copyright {
		display: flex;
		margin: 0;
		padding: 0 1.25rem 0 0;
		font-size: 1rem;
		font-weight: 400;
		color: #b2afc6;
		justify-content: flex-start;

		.kt-link {
			@include kt-link-color(kt-base-color(label, 2), kt-brand-color());
		}
	}

	.kt-footer__menu {
		display: flex;
		justify-content: flex-end;
		padding: 0 0 0 0.75rem;
		margin: 0;

		.kt-footer__menu-link {
			margin: 0 0 0 1.25rem;
			padding: 0;
			font-size: 1rem;
			font-weight: 400;

			@include kt-link-color(kt-base-color(label, 3), kt-brand-color());
		}
	}
}

@include kt-desktop {
	.kt-footer {
		margin-top: kt-get($kt-page-padding, desktop);
	}
}

@include kt-tablet-and-mobile {
	.kt-footer {
		margin-top: kt-get($kt-page-padding, mobile);
		padding: 15px 0;
	}
}

@include kt-mobile {
	.kt-footer {
		.kt-container {					
			flex-direction: column;
			justify-content: center;
		}

		.kt-footer__copyright {
			justify-content: center;
			padding: 0;
			order: 2;
		}

		.kt-footer__menu {
			justify-content: center;
			order: 1;
			padding: 0;
			margin:  0 0 0.75rem 0;

			.kt-footer__menu-link {
				&:first-child {
					padding-left: 0;
					margin-left: 0;
				}
			}
		}
	}
}