//
// Forms
//


// Form group
.form-group {
	margin-bottom: 2rem;

	&.form-group-marginless,
	&.form-group-last {
		margin-bottom: 0px;
	}

	label {
		font-size: 1rem;
		font-weight: 400;
	}

	.invalid-feedback,
	.valid-feedback {
		font-weight: 400;
	}

	.form-text {
		font-size: 0.9rem;
	}

	// Space sizing
	&.form-group-md {
		margin-bottom: 1rem;
	}

	&.form-group-sm {
		margin-bottom: 0.5rem;
	}

	&.form-group-xs {
		margin-bottom: 0.25rem;
	}

	&.form-group-last {
		margin-bottom: 0rem;
	}
}

// Form control
.form-control {
	height: calc(1.7em + 1.3rem + 2px);
	padding: 0.7rem 1.2rem;
	border-radius: 10px;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
	color: #2f2963;
	background-color:#f9f9f9;
	min-width:60px;
	border:2px solid #f9f9f9;
	&:focus {
		box-shadow:none !important;
		background-color:#f9f9f9;
		border:2px solid rgba(47, 41, 99, 0.24);
	}

	&:disabled:not(.datepicker),
	&[readonly]:not(.datepicker) {
		background-color: $input-readonly-bg;
		color:#999999;
	}

	// Pill style
	&.form-control-pill {
		border-radius: 50px;
	}
}

// Custom inputs
.custom-select {
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-file {
	width: 100%;
}

.custom-file-input:focus ~ .custom-file-label {
	box-shadow: none !important;
}

.custom-file-label {
	text-align: left;

	&:after {
	    float: left;
	}
}


// Input Group
.input-group {
	@include kt-icons-size((lineawesome: 1.4rem, fontawesome: 1.2rem, flaticon: 1.2rem));

	.input-group-text {
		line-height: 0;
	}

	i {
		color: $input-group-icon-color;
		line-height: 0;
	}

	.form-control.is-valid + .input-group-append,
	.form-control.is-invalid + .input-group-append {
		margin-left: 0;
	}

	.input-group-prepend + .form-control.is-valid,
	.input-group-prepend + .form-control.is-invalid {
		margin-left: 1px;
	}
}

// Validation
.validated {
	.valid-feedback,
	.invalid-feedback {
		display: block;
	}
}
